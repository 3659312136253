import React, { useState } from 'react';
import "./experience.css";

const Experience = () => {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };
    
  return (
    <section className="experience section" id="experience">
        <h2 className="section__title">Experience</h2>
        <span className="section__subtitle">Work & Leadership roles</span>

        <div className="experience__container container grid">
            <div className="experience__content">
                <div>
                    <i className="bx bx-code-alt experience__icon"></i>
                    <h3 className="experience__title">
                        Web Developer
                    </h3>
                    <h4 className="experience__subtitle"><a className="experience__link" href="https://www.ricethresher.org/">The Rice Thresher</a></h4>
                </div>
                
                <span className="experience__button" onClick={() => toggleTab(1)}>
                    View More 
                    <i className="uil uil-arrow-right experience__button-icon"></i>
                </span>                
                
                <div className={toggleState === 1 ? "experience__modal active-modal" : "experience__modal"}>
                    <div className="experience__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times experience__modal-close"></i>

                        <h3 className="experience__modal-title">Web Developer</h3>
                        <p className="experience__modal-description">
                            Develop the website to host the Thresher’s special projects      
                        </p>
                        
                        <ul className="experience__modal-experience grid">
                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Front-end web design
                                </p>
                            </li>


                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Build graphics using JS libraries (D3, Svelte, etc)
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Execute advanced data analysis and storytelling
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Design and implement the online version of special projects and other important stories
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Participate in story-writing process
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="experience__content">
                <div>
                    <i className="bx bxs-report experience__icon"></i>
                    <h3 className="experience__title">
                        Programs Chair
                    </h3>
                    <h4 className="experience__subtitle"><a className="experience__link" href="https://nsbe.rice.edu/">Rice NSBE</a></h4>
                </div>
                
                <span className="experience__button" onClick={() => toggleTab(2)}>
                    View More 
                    <i className="uil uil-arrow-right experience__button-icon"></i>
                </span>                
                
                <div className={toggleState === 2 ? "experience__modal active-modal" : "experience__modal"}>
                    <div className="experience__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times experience__modal-close"></i>

                        <h3 className="experience__modal-title">Programs Chair</h3>
                        <p className="experience__modal-description">
                            Serve as the lead coordinator for Rice NSBE events.     
                        </p>
                        
                        <ul className="experience__modal-experience grid">
                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Organize all chapter activities (corporate sponsor events, local opportunities, etc...)
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Collaborate with Fundraising Senator in raising funds from corporate sponsors
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Prepare meeting slides for general body meetings (GBMs)
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Survey chapter members at GBMs
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                    Dialogue between Regional and Rice Executive Boards
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="experience__content">
                <div>
                    <i className="bx bx-chalkboard experience__icon"></i>
                    <h3 className="experience__title">
                        Tech TA
                    </h3>
                    <h4 className="experience__subtitle"><a className="experience__link" href="https://oit.rice.edu/">Rice OIT</a></h4>
                </div>
                
                <span onClick={() => toggleTab(3)} className="experience__button">
                    View More 
                    <i className="uil uil-arrow-right experience__button-icon"></i>
                </span>                
                
                <div className={toggleState === 3 ? "experience__modal active-modal" : "experience__modal"}>
                    <div className="experience__modal-content">
                        <i onClick={() => toggleTab(0)} className="uil uil-times experience__modal-close"></i>

                        <h3 className="experience__modal-title">Technology Technical Assistant</h3>
                        <p className="experience__modal-description">
                            Provide support to university staff to enable effective dual-method course delivery
                        </p>
                        
                        <ul className="experience__modal-experience grid">
                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Assist with Zoom functions, such as breakout rooms, polling, monitoring chat
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Provide some assistance with Canvas, such as publishing recordings
                                </p>

                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Point of contact for remote students with questions during class time
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Troubleshoot AV issues
                                </p>
                            </li>

                            <li className="experience__modal-service">
                                <i className="uil uil-check-circle experience__modal-icon"></i>
                                <p className="experience__modal-info">
                                Setting up room if faculty is teaching remotely
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Experience;