import React, { useState } from 'react';
import "./qualification.css"

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

  return (
    <section className="qualification section">
        <h2 className="section__title">Qualifications</h2>
        <span className="section__subtitle">Personal Journey</span>

        <div className="qualification__container container">
            <div className="qualification__tabs">
                <div 
                    className={
                        toggleState === 1
                        ? "qualification__button qualification__active button--flex"
                        : "qualification__button button--flex"
                    }
                    onClick={() => toggleTab(1)}
                >
                    <i className='bx bxs-graduation qualification icon'></i>
                    Education
                </div>

                <div 
                    className={
                        toggleState === 2
                        ? "qualification__button qualification__active button--flex"
                        : "qualification__button button--flex"
                    }
                    onClick={() => toggleTab(2)}
                >
                    <i className='bx bx-laptop qualification icon'></i> 
                    Bootcamps
                </div>
            </div>

        <div className="qualification__sections">
            <div 
                className={
                    toggleState === 1 
                    ? "qualification__content qualification__content-active"
                    : "qualification__content"
                }
            >
                <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">B.S. Computer Science</h3>
                        <span className="qualification__subtitle">
                            Rice University
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2021 - Present</i>
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                </div>

                <div className="qualification__data">
                    <div></div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                    
                    <div>
                        <h3 className="qualification__title">IB Diploma & Cambridge IGCSE</h3>
                        <span className="qualification__subtitle">
                            SOS-Hermann Gmeiner International College
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2017 - 2021</i>
                        </div>
                    </div>
                </div>

                <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">Exchange Student</h3>
                        <span className="qualification__subtitle">
                            Phillips Exeter Academy
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2019</i>
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                </div>

                <div className="qualification__data">
                    <div></div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>

                    <div>
                        <h3 className="qualification__title">Intro to Programming [Scratch, Mobile App Dev, HTML/CSS]</h3>
                        <span className="qualification__subtitle">
                            CodeCamp Summer Bootcamp
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2014-2016</i>
                        </div>
                    </div>
                </div>
            </div>

            <div 
                className={
                    toggleState === 2 
                    ? "qualification__content qualification__content-active"
                    : "qualification__content"
                }
            > 
                <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">FullStack Web Development Bootcamp</h3>
                        <span className="qualification__subtitle">
                            London App Brewery - Udemy
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2022</i>
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                </div>

                <div className="qualification__data">
                    <div></div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                    
                    <div>
                        <h3 className="qualification__title">Python Pro Bootcamp</h3>
                        <span className="qualification__subtitle">
                            London App Brewery - Udemy
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2022</i>
                        </div>
                    </div>
                </div>

                <div className="qualification__data">
                    <div>
                        <h3 className="qualification__title">Java Programming Masterclass</h3>
                        <span className="qualification__subtitle">
                            Learn Program Academy - Udemy
                        </span>
                        <div className="qualification__calendar">
                            <i className='uil uil-calendar-alt'>2022</i>
                        </div>
                    </div>

                    <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </section>
  )
}

export default Qualification;